import { useState, createRef } from 'react';
import { Avatar, Button, TextField, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';

import Styles from '../../Styles/Styles';
import PasswordStrengthMeter from '../../Components/PasswordStrengthMeter';


const Signup = () => {

    const avatarStyle = { backgroundColor: '#D9D9D9' }
    const [login, setUserEmail] = useState('');
    const [password, setUserPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [errortext, setErrortext] = useState('')
    const [successText, setSuccessText] = useState('')


    const [isMailFormat, setIsMailFormat] = useState(false)
    const [confPwd, setConfPwd] = useState(null)
    const [isRegistraionSuccess, setIsRegistraionSuccess] = useState('none');

    const passwordInputRef = createRef();

    const [loginStyle, setLoginStyle] = useState(Styles.Styles.inputStyle)
    const [passwordStyle, setPasswordStyle] = useState(Styles.Styles.inputStyle)
    const [confPasswordStyle, setConfPasswordStyle] = useState(Styles.Styles.inputStyle)
    const [isPasswordEnable, setIsPasswordEnable] = useState(true)

    const [buttonStyle, setButtonStyle] = useState(Styles.Styles.buttonDisableStyle)
    const [buttonEnable, setButtonEnable] = useState(true)

    const params = {
        login: login,
        passWord: password,
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
      }

    // const handleChange = (getLogin, getPassword, getConfirmPassword) => {

    //     setUserEmail(getLogin)
    //     setUserPassword(getPassword)
    //     setConfirmPassword(getConfirmPassword)

    //     if (validateEmail(getLogin)) {
    //         console.log("Adresse e-mail valide !");
    //         setIsPasswordEnable(false)
    //         setIsMailFormat(true)
    //         setLoginStyle(Styles.Styles.inputStyleSuccess)
    //         setPasswordStyle(Styles.Styles.inputStyle)
    //         setConfPasswordStyle(Styles.Styles.inputStyle)
    //     } else {
    //         console.log("Adresse e-mail non valide.");
    //         setIsPasswordEnable(true)
    //         setIsMailFormat(false)
    //         setLoginStyle(Styles.Styles.inputStyleError)
    //         setPasswordStyle(Styles.Styles.inputStyleDisable)
    //         setConfPasswordStyle(Styles.Styles.inputStyleDisable)
    //     }
    //     if ((isMailFormat == true) && (getPassword === getConfirmPassword) && (getPassword != '' && getConfirmPassword != '')) {
    //         console.log("Same password !");
    //         setConfPwd(true)
    //         setPasswordStyle(Styles.Styles.inputStyleSuccess)
    //         setConfPasswordStyle(Styles.Styles.inputStyleSuccess)

    //         if (isMailFormat) {
    //             setButtonStyle(Styles.Styles.buttonStyle)
    //             setButtonEnable(false)
    //         } else {
    //             setButtonStyle(Styles.Styles.buttonDisableStyle)
    //             setButtonEnable(true)
    //         }

    //     } else {
    //         console.log("Password are diffrents!");
    //         if (isPasswordEnable == false) {
    //             setConfPwd(false)
    //             setPasswordStyle(Styles.Styles.inputStyleError)
    //             setConfPasswordStyle(Styles.Styles.inputStyleError)
    //         }

    //     }


    //     console.log('handleChange -> Check Btn : ' + buttonEnable + ' - Login : ' + getLogin.value + ' - Pwd : ' + getPassword + ' - ConfPwd : ' + getConfirmPassword)
    // }

    const handleOnChange = e => {
        const getID = e.target.id
        const getValue = e.target.value
        console.log('Signin -> handleOnChange -> Value : ' + getValue + ' - Get ID : ' + getID)
        
        
        
        if(getID == 'Username') {
            setUserEmail(getValue)
            if (validateEmail(getValue)) {
                console.log("Valid e-mail address!");
                setIsPasswordEnable(false)
                setIsMailFormat(true)
                setLoginStyle(Styles.Styles.inputStyleSuccess)
                setPasswordStyle(Styles.Styles.inputStyle)
                setConfPasswordStyle(Styles.Styles.inputStyle)
            } else {
                console.log("Invalid e-mail address.");
                setIsPasswordEnable(true)
                setIsMailFormat(false)
                setLoginStyle(Styles.Styles.inputStyleError)
                setPasswordStyle(Styles.Styles.inputStyleDisable)
                setConfPasswordStyle(Styles.Styles.inputStyleDisable)
            }
        }

        if(getID == 'Password') {
            setUserPassword(getValue)
        }

        if(getID == 'ConfPassword') {
            setConfirmPassword(getValue)
            if ((isMailFormat == true) && (password === getValue) && (password != '' && getValue != '')) {
                console.log("Same password !");
                setConfPwd(true)
                setPasswordStyle(Styles.Styles.inputStyleSuccess)
                setConfPasswordStyle(Styles.Styles.inputStyleSuccess)
    
                if (isMailFormat) {
                    setButtonStyle(Styles.Styles.buttonStyle)
                    setButtonEnable(false)
                } else {
                    setButtonStyle(Styles.Styles.buttonDisableStyle)
                    setButtonEnable(true)
                }
    
            } else {
                console.log("Password are diffrents!");
                if (isPasswordEnable == false) {
                    setConfPwd(false)
                    setPasswordStyle(Styles.Styles.inputStyleError)
                    setConfPasswordStyle(Styles.Styles.inputStyleError)
                }
    
            }
        }
    }


    return (


        <>
            <Grid align='center'>
                <Avatar style={avatarStyle}><LockOutlined style={{ color: '#002A57' }} /></Avatar>
                <h2>Signup</h2>
            </Grid>
            <TextField
                id="Username"
                label="Username"
                placeholder='Enter Your Username'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChangeText={ handleOnChange } // (UserEmail) => setUserEmail(UserEmail)
                onChange={ handleOnChange }
            />

            <TextField
                id="Password"
                label="Password"
                placeholder='Enter Your Password'
                type='password'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChangeText={ handleOnChange }
                onChange={ handleOnChange } />

            <Typography style={Styles.Styles.SectionStyleMinus}>
                <PasswordStrengthMeter password={password} />
            </Typography>

            <TextField
                id="ConfPassword"
                label="Confirmation Password"
                placeholder='Confirm Your Password'
                type='password'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChangeText={ handleOnChange }
                onChange={ handleOnChange } />

            <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember Me"
                sx={{ marginBottom: 4 }} />

            <Button
                sx={{ bgcolor: blue[700], marginBottom: 4 }}
                type='submit'
                color='primary'
                variant="contained"
                fullWidth
                disabled={buttonEnable}
                >Login</Button>

        </>

    )
}

export default Signup