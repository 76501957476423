import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import '../Styles/App.css'

const STLViewer = ({ base64Data, extesion }) => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;

    // Initialiser la scène
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    mount.appendChild(renderer.domElement);

    // Ajouter une lumière
    const light = new THREE.DirectionalLight(0xffffff, 5);
    light.position.set(5, 5, 5).normalize();
    scene.add(light);

    const light2 = new THREE.DirectionalLight(0xffffff, 5);
    light2.position.set(-5, -5, -5).normalize();
    scene.add(light2);

    scene.background = new THREE.Color(0xffffff)

    // Ajouter une lumière ambiante pour équilibrer l'éclairage global
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5); // Lumière douce
    scene.add(ambientLight);

    // Fonction pour décoder base64 en ArrayBuffer
    const base64ToArrayBuffer = (base64) => {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    };
    var loader = null
    if(extesion === "stl") {
      loader = new STLLoader();
    }
    if(extesion === "ply") {
      loader = new PLYLoader();
    }
    // Charger le fichier STL
    // const loader = new STLLoader();
    const arrayBuffer = base64ToArrayBuffer(base64Data);
    const geometry = loader.parse(arrayBuffer);

    // Centrer l'objet
    geometry.center();

    // Ajuster la taille de l'objet
    const bbox = new THREE.Box3().setFromObject(new THREE.Mesh(geometry))
    const size = new THREE.Vector3()
    bbox.getSize(size)

    const scaleFactor = 200 / Math.max(size.x, size.y, size.z)
    geometry.scale(scaleFactor, scaleFactor, scaleFactor)

    const material = new THREE.MeshPhongMaterial({ color: 0xbcbcbc });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    // Appliquer une rotation de 90 degrés autour de l'axe X
    mesh.rotation.x = -Math.PI / 2; // 90 degrés en radians

    // Positionner la caméra plus loin
    camera.position.z = 200;

    // Ajouter les contrôles orbitaux
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Ajoute un effet d'inertie
    controls.dampingFactor = 0.01; // Facteur d'inertie
    controls.screenSpacePanning = true; // Empêche le mouvement vertical
    controls.maxPolarAngle = Math.PI / 2; // Limiter le mouvement vers le bas

    // Fonction d'animation
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update(); // Mise à jour des contrôles à chaque frame
      // mesh.rotation.x += 0.01;
      // mesh.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    // Nettoyage à la destruction du composant
    return () => {
      mount.removeChild(renderer.domElement);
    };
  }, [base64Data]);

  return (
    <div
      className='scene-container'
      ref={mountRef}
      // style={{ width: '100%', maxHeight: '40hw' }}
    />
  );
};



export default STLViewer;

