
import '../Styles/App.css';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

import { APIUrl, APIMainRequestGET, APIMainRequestPOST } from '../Manager/CommManager.js';

import Header from '../Components/Header.js';
import NavBar from '../Components/NavBar.js';
import Footer from '../Components/Footer.js';

import Dashboard from './Dashboard.js';
import Lasts from './Lasts.js';
import Users from './AuthUsers/Users.js';
import Account from './Account.js';
import Settings from './Settings.js';
import Products from './Products.js';
import Faqs from './Faqs.js';
import Plans from './Plans.js'
import Auth from './AuthUsers/Auth.js';

const Home = () => {
  // const navigate = useNavigate();
  const [connected, setConnected] = useState(true)
  const [queryParameters] = useSearchParams()
  // const [data, setData] = useContext(ApiContext)
  //  const [witchPage, seWitchPage] = useState(<Dashboard getWitchPage={getWitchPage}/>);
  const [witchPage, seWitchPage] = useState();

  const UseQuery = () => new URLSearchParams(window.location.search)

  const getMerchantHubShopInfo = (getToken, getMerchantShop) => {
    console.log('Home -> getMerchantHubShopInfo ->');
    const query = UseQuery()
    // const getToken = query.get('accessToken')
    // const getMerchantShop = query.get('merchantShop')

    if (getToken !== null) {
      console.log('Home -> useEffect -> Get getMerchantShop : ' + getMerchantShop);
      console.log('Home -> useEffect -> Get getToken : ' + getToken);
      const makeRequest = `${APIUrl}/shopify/shopinfo/?setToken=${getToken}&setShop=${getMerchantShop}`

      const settingsParams = { perPage: 0 }

      APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
        return response.getData
      }).then(function (myJson) {
        console.log('Home -> useEffect -> shop info : ' + JSON.stringify(myJson, null, 2))

        localStorage.setItem('merchantID', myJson.shop.id)
        // storeData('merchantID', myJson.shop.id)

        const AddMerchantParams = {
          id: myJson.shop.id,
          name: myJson.shop.name,
          email: myJson.shop.email,
          login: myJson.shop.email,
          companyName: myJson.shop.name,
          country: myJson.shop.country_name,
          shopUrl: getMerchantShop

        }
        console.log('Home -> useEffect -> Add merchant with params : ' + JSON.stringify(AddMerchantParams, null, 2))
        APIMainRequestPOST('/merchants/add', AddMerchantParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('Home -> useEffect -> Add merchant -> Feedback add merchant : ' + JSON.stringify(myJson, null, 2))

        }).finally(() => {
          console.log('Home -> useEffect -> Add merchant -> Finally ->')
        })

      }).finally(() => {
        console.log('Home -> useEffect -> shop info -> Finally ->')
      })
    }
    // console.log('NavBar -> getMerchantHubShopInfo -> query : ' + query);
  }

  const AuthTest = (shop) => {
    console.log('Home -> AuthTest -> shop : ' + shop)

    const settingsAccessParams = { perPage: 0 }

    console.log('Home -> AuthTest -> settingsAccessParams : ' + JSON.stringify(settingsAccessParams, null, 2))

    const makeAccessRequest = `${APIUrl}/shopify/checkaccess/?shop=${shop}`

    APIMainRequestGET(makeAccessRequest, settingsAccessParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {

      console.log('Home -> AuthTest -> myJson : ' + JSON.stringify(myJson, null, 2))

      if (myJson.success == true) {

        // storeData('localShop', shop)
        localStorage.setItem('localShop', shop)
        // storeData('localAccessToken', myJson.accessToken)
        localStorage.setItem('localAccessToken', myJson.accessToken)

        if (myJson.success == true && myJson.accessToken != null) {
          console.log('Home -> AuthTest -> Shop exist with this token : ' + myJson.accessToken)
          setConnected(true)
          getMerchantHubShopInfo(myJson.accessToken, shop)

        } else {

          const settingsParams = { perPage: 0 }
          console.log('Home -> AuthTest -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
          const makeRequest = `${APIUrl}/shopify/auth?shop=${shop}`

          APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
          }).then(function (myJson) {
            console.log('Home -> AuthTest -> myJson : ' + JSON.stringify(myJson, null, 2))
            // setAuthCallbackURL(myJson.installUrl)
            const goToAuthUrl = new URL(myJson.installUrl)
            window.parent.location.href = goToAuthUrl.href.toString()

          }).finally(() => {
            console.log('Home -> AuthTest -> Finally ->')

          })

        }
      } else {
        const settingsParams = { perPage: 0 }
        console.log('Home -> AuthTest -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `${APIUrl}/shopify/auth?shop=${shop}`

        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('Home -> AuthTest -> myJson : ' + JSON.stringify(myJson, null, 2))
          // setAuthCallbackURL(myJson.installUrl)
          const goToAuthUrl = new URL(myJson.installUrl)
          window.parent.location.href = goToAuthUrl.href.toString()

        }).finally(() => {
          console.log('Home -> AuthTest -> Finally ->')

        })
      }

    }).finally(() => {
      console.log('Home -> AuthTest -> Finally ->')

    })

  }

  const getWitchPage = (witchPage) => {
    console.log('Home -> displayWitchPage -> witchPage : ' + witchPage);

    if (witchPage == 'Dashboard') {
      seWitchPage(<Dashboard getWitchPage={getWitchPage} />)
    }
    if (witchPage == 'Lasts') {
      console.log('Home -> displayWitchPage -> clientsList : ' + witchPage);
      seWitchPage(<Lasts getWitchPage={getWitchPage()} />)
    }
    if (witchPage == 'Users') {
      seWitchPage(<Users getWitchPage={getWitchPage} />)
    }
    if (witchPage == 'Account') {
      seWitchPage(<Account getWitchPage={getWitchPage} />)
    }

    if (witchPage == 'Settings') {
      seWitchPage(<Settings getWitchPage={getWitchPage} />)
    }

    if (witchPage == 'Products') {
      seWitchPage(<Products getWitchPage={getWitchPage} />)
    }

    if (witchPage == 'FAQs') {

      seWitchPage(<Faqs getWitchPage={getWitchPage} />)

    }
    if (witchPage == 'Plans') {

      seWitchPage(<Plans getWitchPage={getWitchPage} />)

    }
    // if (witchPage == 'LoginScreen') {
    //   navigation.navigate('LoginScreen')
    // }

  }

  useEffect(() => {
    // const query = new URLSearchParams(window.location.href)
    // const shearchAuth = query.get('/auth')
    // console.log('App -> useEffect -> Current URL param : ' + shearchAuth);
    console.log('Home -> useEffect -> Current URL : ' + window.location.href);
    console.log('Home -> useEffect -> Current URL : ' + window.location.pathname);
    const query = UseQuery()
    const getShopParams = query.get('shop')
    console.log('Home -> useEffect -> Get URL Param : ' + getShopParams);
    if (getShopParams !== null) {
      console.log('Home -> useEffect -> getShopParams !== null ');
      AuthTest(getShopParams)
    } else {
      console.log('Home -> useEffect -> getShopParams == null ');
      setConnected(false)
    }

    console.log('Home -> useEffect -> ');
    getWitchPage('Dashboard')
  }, []);

  return (
    <>
      {
        connected === true ?
          <Box>
            <Stack direction="row">
              <Header />
            </Stack>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
              <Box sx={{ minWidth: 260 }}>
                <NavBar getWitchPage={getWitchPage} />
              </Box>
              <Box sx={{ bgcolor: grey[100], width: '100%' }}>
                {
                  witchPage
                }
              </Box>
            </Stack>
            <Stack direction="row">
              <Footer />
            </Stack>
          </Box>
          :
          <Auth />
      }
    </>
  );
};

export default Home;