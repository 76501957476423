import React, { useState, useEffect } from 'react';
import { Button, Box, useMediaQuery, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
 } from '@mui/material';
import {
    Cancel, Save, Delete, Edit, Add,
    CheckCircleOutline
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { red, green, blue, grey } from '@mui/material/colors';

import { APIMainRequestGET, APIMainRequestPOST, APIMainRequestPATCH, APIMainRequestDELETE } from '../Manager/CommManager';
import { DateOnly } from '../Manager/UnixTimestampToDate';


const initialRows = [
    {
        id: "123456789",
        merchantID: "987654321",
        active: true,
        name: "Default Name",
        size: "0",
        width: "A",
        entryDate: "1730052644",
        updateDate: "1730053529"
    }
];

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        console.log('StandardManager -> EditToolbar -> handleClick')
        const id = randomId();
        console.log('StandardManager -> EditToolbar -> handleClick -> RandomID : ' + id)
        console.log('StandardManager -> EditToolbar -> handleClick -> setRows : ' + JSON.stringify(setRows, null, 2))
        console.log('StandardManager -> EditToolbar -> handleClick -> setRowModesModel : ' + JSON.stringify(setRowModesModel, null, 2))
        setRows((oldRows) => [
            ...oldRows,
            { id, name: '', size: '', width: '', isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<Add />} onClick={handleClick}>
                Add record
            </Button>
            <GridToolbar />
        </GridToolbarContainer>
    );
}

export default function StandardManager() {
    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [idRowSelected, setIDRowSelected] = React.useState('');

    const [getStandardtList, setStandardList] = useState([])

    const handleRowEditStop = (params, event) => {
        console.log('StandardManager -> handleRowEditStop -> ')
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        console.log('StandardManager -> handleEditClick -> id : ' + id)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        console.log('StandardManager -> handleSaveClick -> id : ' + id)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        console.log('StandardManager -> handleDeleteClick -> id : ' + id)
        const editedRow = rows.find((row) => row.id === id);
        console.log('StandardManager -> handleDeleteClick -> _id : ' + JSON.stringify(editedRow, null, 2))
        setIDRowSelected(editedRow.id)
        setAlertIsOpen(true)
        setTitleAlert('Confirm to delete !')
        setMessageAlert('Are you sure to delete this item ?')
        setIdLast(editedRow._id)
        
    };

    const handleCancelClick = (id) => () => {
        console.log('StandardManager -> handleCancelClick -> id : ' + id)
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        console.log('StandardManager -> processRowUpdate -> newRow.id : ' + newRow.id)
        console.log('StandardManager -> processRowUpdate -> newRow : ' + JSON.stringify(newRow, null, 2))
        var updatedRow = {}
        if (newRow.isNew === true) {
            updatedRow = { ...newRow, isNew: false };
            console.log('StandardManager -> processRowUpdate -> AddRow : ' + JSON.stringify(updatedRow, null, 2))
            const merchantID = localStorage.getItem('merchantID')
            const settingsParams = {
                active: updatedRow.active,
                merchantID: merchantID,
                name: updatedRow.name,
                size: updatedRow.size,
                width: updatedRow.width,
            }
            standardAdd(settingsParams)

        } else {
            updatedRow = { ...newRow, isNew: false };
            console.log('StandardManager -> processRowUpdate -> updatedRow : ' + JSON.stringify(updatedRow, null, 2))

            const settingsParams = {
                active: updatedRow.active,
                name: updatedRow.name,
                size: updatedRow.size,
                width: updatedRow.width,
            }
            standardUpdate(updatedRow._id, settingsParams)
        }


        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        console.log('StandardManager -> handleRowModesModelChange -> newRowModesModel : ' + JSON.stringify(newRowModesModel, null, 2))
        console.log('StandardManager -> handleRowModesModelChange -> rowModesModel : ' + JSON.stringify(rowModesModel, null, 2))
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            editable: false
        },
        {
            field: 'merchantID',
            headerName: 'ID Merchant',
            width: 50,
            editable: false,
        },
        {
            field: 'active',
            headerName: 'Active',
            type: 'boolean',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderCell: (params) => {
                return (
                    params.value === true ? <CheckCircleOutline sx={{ color: green[600] }} /> : <Cancel sx={{ color: red[600] }} />
                );
            },
        },
        {
            field: 'name',
            headerName: 'Standard name',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            type: 'number',
        },
        {
            field: 'width',
            headerName: 'Width',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'entryDate',
            headerName: 'Added',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            renderCell: (params) => { return (<><DateOnly unixTimestamp={params.value} /></>); },
        },
        {
            field: 'updateDate',
            headerName: 'Updated',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            renderCell: (params) => { return (<><DateOnly unixTimestamp={params.value} /></>); },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const initialState = {
        columns: {
            columnVisibilityModel: {

                id: false,
                merchantID: false,
                active: true,
                name: true,
                size: true,
                width: true,
                entryDate: true,
                updateDate: true,
            }
        },
        // pagination: { paginationModel: { pageSize: 6, page: 0 } }
    };

    

    // Alert View
    const [idLast, setIdLast] = useState('')
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [titleAlert, setTitleAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    
    const handleAlertCloseAgree = () => {
        console.log('DialogAlert -> handleAlertCloseAgree');
        
        standardDelete(idLast)
        setRows(rows.filter((row) => row.id !== idRowSelected));
        setAlertIsOpen(false);
        setIDRowSelected('')
    };

    const handleAlertCloseDisagree = () => {
        console.log('DialogAlert -> handleAlertCloseDisagree');
        setAlertIsOpen(false);
        setIDRowSelected('')
    };

    const DialogAlert = ({ title, message, cancelBtn, validateBtn }) => {
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={alertIsOpen}
                    onClose={handleAlertCloseDisagree}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title"> {title} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {message} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleAlertCloseDisagree}> {cancelBtn} </Button>
                        <Button onClick={handleAlertCloseAgree} autoFocus> {validateBtn} </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    const standardDelete = (stdID) => {
        console.log('StandardManager -> standardDelete -> ')

        const merchantID = localStorage.getItem('merchantID')
        const standardID = stdID
        const settingsParams = {
            active: 0
        }

        console.log('StandardManager -> standardDelete -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/lcs/std/${merchantID}/${standardID}`
        APIMainRequestDELETE(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> standardDelete : ' + JSON.stringify(myJson, null, 2))

            setStandardList(myJson)
            setRows(myJson)

        }).finally(() => {
            console.log('StandardManager -> standardDelete -> Finally : ')

        })

    }

    const standardAdd = (bodyParams) => {
        console.log('StandardManager -> standardAdd -> ')

        console.log('StandardManager -> standardAdd -> settingsParams : ' + JSON.stringify(bodyParams, null, 2))
        const makeRequest = `/lcs/std/`
        APIMainRequestPOST(makeRequest, bodyParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> standardAdd : ' + JSON.stringify(myJson, null, 2))

            setStandardList(myJson)
            setRows(myJson)

        }).finally(() => {
            console.log('StandardManager -> standardAdd -> Finally : ')

        })

    }

    const standardUpdate = (stdID, bodyParams) => {
        console.log('StandardManager -> standardUpdate -> ')

        const merchantID = localStorage.getItem('merchantID')
        const standardID = stdID

        console.log('StandardManager -> standardUpdate -> settingsParams : ' + JSON.stringify(bodyParams, null, 2))
        const makeRequest = `/lcs/std/${merchantID}/${standardID}`
        APIMainRequestPATCH(makeRequest, bodyParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> standardUpdate : ' + JSON.stringify(myJson, null, 2))

            setStandardList(myJson)
            setRows(myJson)

        }).finally(() => {
            console.log('StandardManager -> standardUpdate -> Finally : ')

        })

    }

    const standardList = () => {
        console.log('StandardManager -> LastListRequest -> ')

        const merchantID = localStorage.getItem('merchantID')
        const settingsParams = {
            value: 0
        }
        console.log('StandardManager -> StandardList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/lcs/std/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> StandardList : ' + JSON.stringify(myJson, null, 2))

            setStandardList(myJson)
            setRows(myJson)

        }).finally(() => {
            console.log('StandardManager -> StandardList -> Finally : ')

        })

    }

    useEffect(() => {
        console.log('StandardManager -> useEffect');
        const merchantID = localStorage.getItem('merchantID')
        standardList()
    }, []);

    return (
        <Box
            sx={{
                height: '70hw',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DialogAlert title={titleAlert} message={messageAlert} cancelBtn={'Cancel'} validateBtn={'Confirm'} />
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                // getRowId={(getStandardtList) => getStandardtList._id}
                initialState={initialState}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,

                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </Box>
    );
}
