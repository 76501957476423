import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { ListItemText, ListItemIcon, ListItemButton, ListItem, Divider, List, Box } from '@mui/material';
import { Logout, Login, Home, People, ViewInAr, Settings, ShowChart, AccountBox, PriceCheck, Store, QuestionMark } from '@mui/icons-material';
import Styles from "../Styles/Styles";

const NavBar = ({ getWitchPage }) => {
    const [connected, setConnected] = useState(true)
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    //// Original navBar list : ['Dashboard', 'Settings', 'Products', 'Lasts', 'Plans', 'Account', 'FAQs', 'Users']
    const navBarListName = ['Dashboard', 'Products', 'Lasts', 'Plans', 'Account', 'Settings', 'FAQs']

    const navBarStruct = [
        { id: 0, name: 'Dashboard', icon: <Home /> },
        { id: 1, name: 'Products', icon: <Store /> },
        { id: 2, name: 'Lasts', icon: <ViewInAr /> },
        { id: 3, name: 'Plans', icon: <PriceCheck /> },
        { id: 4, name: 'Account', icon: <AccountBox /> },
        { id: 5, name: 'Settings', icon: <Settings /> },
        { id: 6, name: 'FAQs', icon: <QuestionMark /> },
        { id: 7, name: 'Users', icon: <People /> },

    ]

    const navigate = useNavigate()

    const handleListItemClick = (witchSection, index) => {
        console.log('NavBar Action -> witch Pages : ' + witchSection)
        setSelectedIndex(index);
        selectSection(witchSection)

    };
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const selectSection = (witchSection) => {
        console.log('NavBar Action -> witch Pages : ' + witchSection)
        // setActive(witchSection)

        getWitchPage(witchSection)
    }

    const logOut = () => {
        console.log('NavBar -> logOut ->');
        localStorage.clear()
        const goToAuthUrl = new URL('https://dashboard.3dfitter.com')
        window.parent.location.href = goToAuthUrl.href.toString()
    }

    useEffect(() => {
        console.log('NavBar -> useEffect -> Index : ' + selectedIndex);
        console.log('NavBar -> useEffect -> Current URL : ' + window.location.href);
        console.log('NavBar -> useEffect -> Current URL : ' + window.location.pathname);


        setConnected(true)

    }, []);

    const navBarItem = (index, title, icon) => {
        return (
            <ListItem key={index}>
                <ListItemButton
                    sx={selectedIndex === index ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                    onClick={() => { handleListItemClick(title, index) }} >

                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} />
                </ListItemButton>
            </ListItem>
        )
    }

    const returnItem = (index, text, icon) => {



        switch (index) {
            case 0:
                return <ListItem key={text}>
                    <ListItemButton
                        //href="/"
                        sx={selectedIndex === 0 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 0) }} >

                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 1:
                return <ListItem key={text}>
                    <ListItemButton //href="/settings"
                        sx={selectedIndex === 1 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 1) }} >
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 2:
                return <ListItem key={text} >
                    <ListItemButton //href="/products"
                        sx={selectedIndex === 2 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 2) }} >
                        <ListItemIcon>
                            <Store />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 3:
                return <ListItem key={text} >
                    <ListItemButton //href="/lasts"
                        sx={selectedIndex === 3 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 3) }} >
                        <ListItemIcon>
                            <ViewInAr />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 4:
                return <ListItem key={text}>
                    <ListItemButton //href="/plans"
                        sx={selectedIndex === 4 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 4) }} >
                        <ListItemIcon>
                            <PriceCheck />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 5:
                return <ListItem key={text} >
                    <ListItemButton //href="/account"
                        sx={selectedIndex === 5 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 5) }} >
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 6:
                return <ListItem key={text} >
                    <ListItemButton //href="/faqs"
                        sx={selectedIndex === 6 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 6) }} >
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 7:
                return <ListItem key={text} >
                    <ListItemButton //href="/users"
                        sx={selectedIndex === 7 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 7) }} >
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            default:
                return
        }
    }

    const matchingItems = navBarStruct.filter(item => navBarListName.includes(item.name));


    const DrawerList = (
        <Box sx={{ minWidth: 250, width: 250, height: '92vh' }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {
                    matchingItems.map((item) => (
                        navBarItem(item.id, item.name, item.icon)
                    ))
                }
            </List>
            <Divider />
            <List>
                {
                    connected ?
                        <ListItemButton
                            onClick={() => { logOut() }}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItemButton>
                        : <ListItemButton
                            // onClick={() => { logOut() }}
                            >
                            <ListItemIcon>
                                <Login />
                            </ListItemIcon>
                            <ListItemText primary='Login' />
                        </ListItemButton>
                }

            </List>
        </Box >
    );

    return (
        <div>
            {DrawerList}
        </div>
    );
};


export default NavBar;