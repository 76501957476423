import '../Styles/App.css';
import { useState, useEffect } from 'react';
import {
    Box, Tab, Tabs, Typography
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';


import { APIUrl, APIMainRequestGET } from '../Manager/CommManager';



const Account = ({ getWitchPage }) => {
    // const navigation = useNavigate();

    const [getMerchantInfo, setMerchantInfo] = useState({})

    const getShopifyMerchantInfo = () => {
        console.log('Account -> getShopifyMerchantInfo ->');

        const getMerchantShop = localStorage.getItem('localShop')
        console.log('Account -> getShopifyMerchantInfo -> getMerchantShop : ' + getMerchantShop);

        const getToken = localStorage.getItem('localAccessToken')
        console.log('Account -> getShopifyMerchantInfo -> getToken : ' + getToken);

        const makeRequest = `${APIUrl}/shopify/shopinfo/?setToken=${getToken}&setShop=${getMerchantShop}`

        const settingsParams = { perPage: 0 }

        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Account -> getShopifyMerchantInfo -> shop info : ' + JSON.stringify(myJson, null, 2))

            setMerchantInfo(myJson.shop)

        }).finally(() => {
            console.log('Account -> getShopifyMerchantInfo -> shop info -> Finally ->')
        })
    }

    useEffect(() => {

        console.log('Account -> useEffect ->');
        getShopifyMerchantInfo()

    }, []);

    return (
        <Box sx={{ flexGrow: 1, flexDirection: 'column', paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
            <Typography sx={{
                borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
            }} variant="h4">Account</Typography>

            <Typography sx={{
                borderColor: grey[400], borderStyle: 'solid', color: grey[600],
                borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
            }} variant="h4">{JSON.stringify(getMerchantInfo, null, 2)}</Typography>
        </Box>
    );
};

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};

export default Account;