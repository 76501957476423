import '../Styles/App.css';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
    Box, useMediaQuery, Button, Stack, Typography, Switch, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    Fade, Modal, Backdrop
} from '@mui/material';

import {
    WarningAmberOutlined, Close, Delete, Add, Save, Cancel, Edit
} from '@mui/icons-material';
import { red, blue, grey } from '@mui/material/colors';
import { APIMainRequestPOST, APIMainRequestGET, APIMainRequestDELETE, APIMainRequestPATCH } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';


const LastsCatManager = () => {
    const [openModal, setOpenModal] = React.useState(false);

    const [getCatList, setCatList] = useState([])
    const [idCat, setIdCat] = useState('')

    // Alert View
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [titleAlert, setTitleAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const handleAlertCloseAgree = () => {
        console.log('DialogAlert -> handleAlertCloseAgree');
        deleteLastCat(idCat)
        setCatList(getCatList.filter((row) => row._id !== idCat))
        setAlertIsOpen(false);
    };
    const handleAlertCloseDisagree = () => {
        console.log('DialogAlert -> handleAlertCloseDisagree');
        setAlertIsOpen(false);
    };
    const DialogAlert = ({ title, message, cancelBtn, validateBtn }) => {
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={alertIsOpen}
                    onClose={handleAlertCloseDisagree}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title"> {title} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {message} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleAlertCloseDisagree}> {cancelBtn} </Button>
                        <Button onClick={handleAlertCloseAgree} autoFocus> {validateBtn} </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    // SnackBar
    const [open, setOpen] = useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')



    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleReset = () => {
        console.log('LastsCatManager -> handleReset -> ')

    };

    const lastCatListRequest = () => {

        const settingsParams = {
            clientID: 'value.parentID',
        }
        APIMainRequestGET('/lasts_cat/list', settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('LastsCatManager -> lastCatListRequest -> ' + JSON.stringify(myJson, null, 2))
            setCatList(myJson)

        }).finally(() => {
            console.log('LastsCatManager -> lastCatListRequest -> Finally : ')
        })

    }

    const handleChange = (event) => {
        console.log('LastsCatManager -> handleChange -> ' + event.target.checked)

    };

    const initialRows = [
        {
            id: 1,
            name: '-',
            description: '-',
            active: false,
        }
    ];

    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        console.log('LastsCatManager -> handleRowEditStop -> params : ' + params)
        console.log('LastsCatManager -> handleRowEditStop -> event : ' + event)
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        console.log('LastsCatManager -> handleEditClick -> id : ' + id)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        // setRows(rows.filter((row) => row.id !== id));
        console.log('LastsCatManager -> handleDeleteClick -> id : ' + id)

        setAlertIsOpen(true)
        setTitleAlert('Confirm to delete !')
        setMessageAlert('Are you sure to delete this last category ?')
        setIdCat(id)


    };

    const handleCancelClick = (id) => () => {
        console.log('LastsCatManager -> handleCancelClick -> id : ' + id)
        deleteLastCat(id)
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        // const editedRow = rows.find((row) => row.id === id);
        const editedRow = getCatList.find((row) => row._id === id);
        if (editedRow.isNew) {
            //   setRows(rows.filter((row) => row.id !== id));
            setCatList(getCatList.filter((row) => row._id !== id))
        }
    };

    const processRowUpdate = (newRow) => {
        console.log('LastsCatManager -> processRowUpdate -> newRow : ' + JSON.stringify(newRow, null, 2))

        updateLastCat(newRow)
        const updatedRow = { ...newRow, isNew: false };
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setCatList(getCatList.map((row) => (row._id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const addCatBtn = () => {
        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<Add />} onClick={addEmptyLastCat}>
                    Add category
                </Button>
            </GridToolbarContainer>
        );
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 180, editable: true },
        {
            field: 'description',
            headerName: 'Description',
            width: 170,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 100,
            editable: true,
            renderCell: (params) => {
                // console.log('LastsCatManager -> Header -> Active : ' + params.value)
                return (
                    <>
                        {params.value == true ?
                            <Switch
                                checked={true}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /> :
                            <Switch
                                checked={false}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}

                    </>
                );
            },
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                
                if(isInEditMode) {
                    return (
                        <><Switch
                        checked={true}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                            {/* {params.value == true ?
                                <Switch
                                    checked={true}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /> :
                                <Switch
                                    checked={false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} */}
    
                        </>
                    );
                }
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const returnListCat = () => {


        return (
            <Box sx={{ width: '100%' }}>

                <DataGrid
                    rows={getCatList}
                    columns={columns}
                    getRowId={(getCatList) => getCatList._id}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: addCatBtn,
                    }}
                    slotProps={{
                        toolbar: { setCatList, setRowModesModel }
                    }}
                />
            </Box>
        )

    }

    const addEmptyLastCat = () => {
        const settingsParams = {
            name: '',
            description: '',

        }
        APIMainRequestPOST('/lasts_cat/add', settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('LastsCatManager -> /lasts_cat/add -> ' + JSON.stringify(myJson, null, 2))

            lastCatListRequest()

        }).finally(() => {
            console.log('LastsCatManager -> DepartementMGT -> Finally : ')
        })
    }

    const updateLastCat = (data) => {
        console.log('LastsCatManager -> updateLastCat -> data : ' + JSON.stringify(data, null, 2));
        const updateParm = {
            name: data.name,
            description: data.description,
        }
        console.log('LastsCatManager -> updateLastCat -> updateParm : ' + JSON.stringify(updateParm, null, 2));

        APIMainRequestPATCH('/lasts_cat/' + data._id, updateParm).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('LastsCatManager -> updateLastCat -> Update Last cat -> ' + JSON.stringify(myJson, null, 2))
            if (myJson.success == true) {
                setMessageTypeDisplayed('success')
                setMessageDisplayed('Your last category ' + data.name + ' as been created !')
                setOpen(true)

            } else {
                setMessageTypeDisplayed('error')
                setMessageDisplayed(myJson.message)
                setOpen(true)
            }
        }).finally(() => {

            console.log('LastsCatManager -> handleSendLast -> Add new last category -> Finally : ')
            setTimeout(() => {
                setOpen(false)

            }, 5000);
        })
    }

    const deleteLastCat = (id) => {
        console.log('LastsCatManager -> deleteLastCat -> id : ' + id)

        console.log('LastsCatManager -> deleteLastCat -> CONFIRME -> id : ' + id)
        const updateParm = {}
        APIMainRequestDELETE('/lasts_cat/' + id, updateParm).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('LastsCatManager -> deleteLastCat -> ' + JSON.stringify(myJson, null, 2))
            if (myJson.success == true) {
                setMessageTypeDisplayed('success')
                setMessageDisplayed(myJson.message)
                setOpen(true)

            } else {
                setMessageTypeDisplayed('error')
                setMessageDisplayed(myJson.message)
                setOpen(true)
            }
        }).finally(() => {
            lastCatListRequest()
            console.log('LastsCatManager -> deleteLastCat -> Finally : ')
            setTimeout(() => {
                setOpen(false)
            }, 5000);
        })
    }

    useEffect(() => {
        console.log('Lasts -> useEffect');

        lastCatListRequest()


    }, []);

    return (
        <div>
            <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
            <DialogAlert title={titleAlert} message={messageAlert} cancelBtn={'Cancel'} validateBtn={'Confirm'} />

            <Stack
                direction="row"
                sx={{
                    marginTop: 2,
                    minWidth: 500
                }} >

                {
                    returnListCat()
                }

            </Stack>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={styleModal}>
                        <Close
                            style={{ position: 'absolute', top: '2%', right: '2%', width: '3vw', height: '3vw', color: blue[700] }}
                            onClick={() => { handleCloseModal() }} />

                        <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >
                            <WarningAmberOutlined sx={{
                                width: '100%',
                                height: 65,
                                color: red[700],
                            }} />
                            <Typography id="transition-modal-title" variant="h3" component="h2">
                                Attention
                            </Typography>
                            <Typography id="transition-modal-title" variant="body1" component="h2">
                                The max size autorized is 10 Mo
                            </Typography>
                            <Button
                                onClick={handleCloseModal}
                                sx={{
                                    mt: 1, mr: 1,
                                    backgroundColor: blue[700], color: grey[50],
                                    width: '50%',
                                    alignSelf: 'center'
                                }}>
                                OK
                            </Button>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};

export default LastsCatManager;