import { useState } from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}


// getMessage = Message that we want display
// getType = Type of alert view that we want Ex : error, warning, info, success
const SnackBarManager = ({ isOpen, getMessage, getType }) => {

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    isOpen = false

    // setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={isOpen}
        onClose={handleClose} 
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
        style={{ position: 'fixed', left: '10%', width: '80%', bottom: '8%' }}>
        <Alert onClose={handleClose} severity={getType} sx={{ width: '100%' }}>
          {getMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SnackBarManager