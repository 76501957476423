import * as React from 'react';
import { Avatar, Button, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { blue } from '@mui/material/colors';


const Signin = () => {

    const avatarStyle = { backgroundColor: '#D9D9D9' }
    const handleOnChange = e => {
        const getID = e.target.id
        const getValue = e.target.value
        console.log('Signin -> handleOnChange -> Value : ' + getValue + ' - Get ID : ' + getID)
    }

    return (
        <>
            <Grid align='center'>
                <Avatar style={avatarStyle}><LockOutlined style={{ color: '#002A57' }} /></Avatar>
                <h2>Signin</h2>
            </Grid>
            <TextField
                id="Username"
                label="Username"
                placeholder='Enter Your Username'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChange={handleOnChange} />

            <TextField
                id="Password"
                label="Password"
                placeholder='Enter Your Password'
                type='password'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChange={handleOnChange} />

            <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember Me"
                sx={{ marginBottom: 4 }} />

            <Button
                sx={{ bgcolor: blue[700], marginBottom: 4 }}
                type='submit'
                color='primary'
                variant="contained"
                fullWidth>Login</Button>
        </>

    )
}

export default Signin