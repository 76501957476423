import '../Styles/App.css';
import leftFoot from '../Assets/leftFoot.png';
import rightFoot from '../Assets/rightFoot.png';
import React, { useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar, IconButton, Chip, TextField, ToggleButtonGroup, ToggleButton, Box, 
  Button, Stack, Typography, CircularProgress, Switch, Backdrop, Autocomplete
} from '@mui/material';
import { CloudDownload, Close, } from '@mui/icons-material';
import { blue, grey, } from '@mui/material/colors';
import { APIUrl, APIMainRequestPOST, APIMainRequestGET } from '../Manager/CommManager';
// STL Stuff
import STLViewer from '../Components/STLViewer';
// STL Stuff

import { DateOnly } from '../Manager/UnixTimestampToDate';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));


const DetailsMerchantLasts = ({ whichProduct, whichLast, closeModalRefreshList }) => {

  const [loading, setLoading] = React.useState(false);

  const [getStandard, setStandard] = useState([])
  const [sizeList, setSizeList] = React.useState([]);
  const [widthList, setWidthList] = React.useState([]);

  const [getOurProductsList, setOurProductsList] = useState()
  const [getProductID, setProductID] = useState()

  const [getBase64StlFile, setBase64StlFile] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const [getDisplayLast, setDisplayLast] = useState(false)

  const [getLastList, setLastList] = useState([])
  const [getDetailLastSelected, setDetailLastSelected] = useState([])




  const ImageProducts = (product) => {
    // const { image } = product;
    console.log('DetailsMerchantLasts -> ImageProducts -> product : ' + JSON.stringify(product, null, 2));
    const imgLink = product.image.src !== null ? product.image.src : 'https://picsum.photos/200'
    const imgAlt = product.image.alt !== null ? product.image.alt : product.title

    return (
      <>
        <Avatar
          sx={{ width: '7vw', height: '7vw' }}
          src={imgLink}
          alt={imgAlt}
        />
      </>
    )
  }

  // Function to return a merchant product by ID
  const getMerchantProductById = (whichLastArray, id) => {
    return whichLastArray.find(products => products.id === `${id}`);
};

  // Function to retreive a last with S/N
  const getLastBySN = (whichLastArray, sn) => {
    return whichLastArray.find(last => last.sn === `${sn}`);
  };

  const merchantProductLast = (prodID) => {
    setLoading(true)
    const fakeParams = {
      perPage: 0,
    }
    const merchantID = localStorage.getItem('merchantID')
    console.log('DetailsMerchantLasts -> merchantProductLast -> Our Products -> merchantID : ' + merchantID + ' - Product ID : ' + prodID)
    const makeProdRequest = `${APIUrl}/merchants/product/last/${merchantID}/${prodID}`
    APIMainRequestGET(makeProdRequest, fakeParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {

      console.log('DetailsMerchantLasts -> merchantProduct : ' + JSON.stringify(myJson, null, 2))
      setOurProductsList(myJson)

      console.log('DetailsMerchantLasts -> merchantProductLast : ' + JSON.stringify(myJson.lasts, null, 2))
      setLastList(myJson.lasts)

      const last = getLastBySN(myJson.lasts, whichLast)
      console.log('DetailsMerchantLasts -> displayLast -> Last S/N : ' + last.sn);
      setDetailLastSelected(last)
      storeLastData(last)
      getStlFile(last.linkPly)

    }).finally(() => {
      console.log('DetailsMerchantLasts -> merchantProductLast -> getOurProductsList -> Finally ->')


    })
  }
  const [lastActive, setLastActive] = React.useState(true);
  const [lastSide, setLastSide] = React.useState(true);

  const [lastStandardName, setLastStandardName] = React.useState(null);
  const [lastSizeValue, setLastSizeValue] = React.useState(null);
  const [lastWidthValue, setLastWidthValue] = React.useState(null);

  const [lastHeightHeel, setLastHeightHeel] = React.useState(null);
  const [lastBrand, setLastBrand] = React.useState(null);
  const [lastModel, setLastModel] = React.useState(null);
  const [lastGender, setLastGender] = React.useState(null);
  const [lastType, setLastType] = React.useState(null);
  const [footLength, setFootLength] = React.useState(null);
  const [footWidth, setFootWidth] = React.useState(null);
  const [lastBallGirth, setLastBallGirth] = React.useState(null);
  const [lastHeelGirth, setLastHeelGirth] = React.useState(null);
  const [lastToeHeigth, setLastToeHeigth] = React.useState(null);
  const [lastLength, setLastLength] = React.useState(null);
  const [lastWidth, setLastWidth] = React.useState(null);
  const [lastDescription, setLastDescription] = React.useState(null);


  const storeLastData = (last) => {
    setLastActive(last.active)
    setLastSide(last.side)
    setLastStandardName(last.standard)
    setLastSizeValue(last.size)
    setLastWidthValue(last.sizeWidth)
    setLastHeightHeel(last.heel)
    setLastBrand(last.brand)
    setLastModel(last.model);
    setLastGender(last.gender)
    setLastType(last.type)
    setFootLength(last.lenght)
    setFootWidth(last.width)
    setLastBallGirth(last.ballGirth)
    setLastHeelGirth(last.heelGirth)
    setLastToeHeigth(last.toeHeight)
    setLastLength(last.lastLenght)
    setLastWidth(last.lastWidth)
    setLastDescription(last.description)
  }

  const handleChangeLeftRight = (event, newValue) => {
    console.log('DetailsMerchantLasts -> handleChangeLeftRight -> newAlignment : ' + newValue);
    setLastSide(newValue);
  };

  const getStandardValue = (newValue) => {

    console.log('DetailsMerchantLasts -> StandardManager -> standardName : ' + newValue.name)
    setLastStandardName(newValue.name)
    setSizeList(newValue.size)
    setWidthList(newValue.width)

  }
  const getHightHeel = (e) => {
    console.log('DetailsMerchantLasts -> Set Heel Hight -> heelValue : ' + e.target.value)
    setLastHeightHeel(e.target.value)
  }

  const getFileExtension = (url) => {
    return url.split('.').pop().split(/\#|\?/)[0];  // Récupère tout après le dernier point avant une ancre (#) ou un paramètre (?)
  };

  const getStlFile = (url) => {

    const setParams = {
      fileUrl: url
    }
    const makeProdRequest = `${APIUrl}/stlFile`
    console.log("DetailsMerchantLasts -> getStlFile -> setParams :" + JSON.stringify(setParams, null, 2));
    console.log("DetailsMerchantLasts -> getStlFile -> makeProdRequest :" + makeProdRequest);
    APIMainRequestPOST('/stlFile', setParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {
      console.log('DetailsMerchantLasts -> getStlFile -> Base 64 response : ' + JSON.stringify(myJson, null, 2))

      setBase64StlFile(myJson.b64)
      const extension = getFileExtension(url);
      setFileExtension(extension)
      console.log("DetailsMerchantLasts -> getStlFile -> Le fichier est de type :", extension);

    }).finally(() => {
      console.log('DetailsMerchantLasts -> getStlFile -> Finally ->')
      setDisplayLast(true)
      setLoading(false)
    })

  }

  const prodVariant = (product, short) => {
    const { variants } = product
    const firstNItems = variants.slice(0, 5);
    return (
      short === true ?
        firstNItems.map((item) =>
          <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
        )
        :
        variants.map((item) =>
          <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
        )
    )
  }

  const editProduct = () => {

  }

  const deleteLast = () => {

  }

  const getAllStandard = () => {
    const settingsParams = {
      perPage: 0,
    }
    const makeRequest = '/lcs/std'
    APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {
      // console.log('StandardManager -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

      setStandard(myJson)

    }).finally(() => {
      console.log('DetailsMerchantLasts -> getStandardList -> Finally : ')
    })
  }

  const download3DFile = (linkFile) => {
    setLoading(true)
    const link = document.createElement("a")
    // link.download = linkFile
    link.href = linkFile
    link.click()
    setLoading(false)
  }

  const getHubShopProductList = () => {
    console.log('DetailsMerchantLasts -> getHubShopProductList -> ');
    
    const getToken = localStorage.getItem('localAccessToken') //getData('localAccessToken')
    const getMerchantShop = localStorage.getItem('localShop') //getData('localShop')
    console.log('DetailsMerchantLasts -> getHubShopProductList -> getMerchantShop : ' + getMerchantShop);
    console.log('DetailsMerchantLasts -> getHubShopProductList -> Get getToken : ' + getToken);
    if (getToken !== null) {
      
        const makeRequest = `${APIUrl}/shopify/products/?setToken=${getToken}&setShop=${getMerchantShop}`
        const settingsParams = {
            perPage: 0,
        }

        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('DetailsMerchantLasts -> useEffect -> getProductsList : ' + JSON.stringify(myJson.products, null, 2))
            // setProductsList(myJson.products)

        }).finally(() => {
            console.log('DetailsMerchantLasts -> useEffect -> getProductsList -> Finally ->')
        })
    }

}

  useEffect(() => {
    console.log('DetailsMerchantLasts -> useEffect');
    console.log('DetailsMerchantLasts -> useEffect -> ID :' + JSON.stringify(whichProduct, null, 2));
    getHubShopProductList()
    setProductID(whichProduct.id)
    merchantProductLast(whichProduct.id)
    getAllStandard()

  }, []);

  return (

    <Box sx={{ flexDirection: 'row', minWidth: 500, overflow: 'scroll' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
        <CircularProgress color="inherit" size={100} sx={{ color: '#fff' }} />
      </Backdrop>
      <IconButton aria-label="settings">
        <Close onClick={() => { closeModalRefreshList() }} />
      </IconButton>
      {loading === true ? <></> :
        <>
          <Stack direction='column' spacing={2} >
            <Stack direction="row" spacing={1} >
              {/* {ImageProducts(whichProduct)} */}
              <Typography id="modal-modal-title" variant="h4" component="h4">
                <b>{whichProduct.title}</b>
              </Typography>
            </Stack>
            {
              getDisplayLast === true ?
                <>
                  <Stack direction="row" spacing={1} >
                    <Typography id="modal-modal-title" variant="h6" component="h6">
                      <b>Product ID :</b> {whichProduct.id}
                    </Typography>
                    <Typography variant="h6" component="h6" sx={{ marginBottom: 2 }}><b>Created :</b> <DateOnly unixTimestamp={getDetailLastSelected.entryDate} /> </Typography>
                    <Typography variant="h6" component="h6" sx={{ marginBottom: 2 }}><b>Updated :</b> <DateOnly unixTimestamp={getDetailLastSelected.updateDate} /> </Typography>
                  </Stack>

                  <Typography sx={{ marginBottom: 2 }} variant="h3" component="h3">Last detail:</Typography>

                  <Stack direction="row" spacing={1} >

                    <Stack direction='column' spacing={2} sx={{ width: '100%' }}>

                      <Stack direction="row" spacing={1} >

                        <Typography>Active : </Typography>
                        {
                          lastActive === true ?
                            <IOSSwitch sx={{ m: 1 }} onChange={() => setLastActive((prevSelected) => !prevSelected)} defaultChecked />
                            : <IOSSwitch sx={{ m: 1 }} onChange={() => setLastActive((prevSelected) => !prevSelected)} />
                        }
                      </Stack>

                      <Typography> <b>S/N :</b> {getDetailLastSelected.sn} </Typography>

                      <ToggleButtonGroup sx={{ color: grey[800] }} value={lastSide} exclusive onChange={handleChangeLeftRight} aria-label="Platform">
                        <ToggleButton value="left">
                          <Avatar alt="Remy Sharp" src={leftFoot} />
                          <Typography> Left </Typography>
                        </ToggleButton>
                        <ToggleButton value="right">
                          <Typography> Right </Typography>
                          <Avatar alt="Remy Sharp" src={rightFoot} />
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <Autocomplete
                        value={lastStandardName}
                        onChange={(event, newValue) => {
                          getStandardValue(newValue)
                        }}
                        disablePortal
                        id="standardName"
                        options={getStandard}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              {option.name}
                            </li>
                          );
                        }}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Standard" />}
                      />
                      <Autocomplete
                        value={lastSizeValue}
                        onChange={(event, newValue) => {
                          setLastSizeValue(newValue);
                        }}
                        disablePortal
                        id="sizeList"
                        options={sizeList.map((item) => item.value)}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Size" />}
                      />
                      <Autocomplete
                        value={lastWidthValue}
                        onChange={(event, newValue) => {
                          setLastWidthValue(newValue);
                        }}
                        disablePortal
                        id="widthList"
                        options={widthList.map((item) => item.name)}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Width" />}
                      />

                    </Stack>

                    <Stack direction='column' spacing={1} sx={{ width: '100%' }}>

                      <STLViewer base64Data={getBase64StlFile} extesion={fileExtension} />

                      <Button variant="contained" onClick={() => download3DFile(getDetailLastSelected.linkPly)} sx={{ backgroundColor: blue[500] }} endIcon={<CloudDownload />}>
                        Download the 3D file
                      </Button>

                    </Stack>

                  </Stack>

                  <Stack direction="row" spacing={1} >

                    <TextField
                      id="brand"
                      label="Brand"
                      defaultValue={lastBrand}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="model"
                      label="Model"
                      defaultValue={lastModel}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="gender"
                      label="Gender"
                      defaultValue={lastGender}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="type"
                      label="Type"
                      defaultValue={lastType}
                      sx={{ width: '100%' }}

                    />

                  </Stack>

                  <Stack direction="row" spacing={1} >

                    <TextField
                      id="lenght"
                      label="Foot Length"
                      type="number"
                      defaultValue={footLength}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="width"
                      label="Foot Width"
                      type="number"
                      defaultValue={footWidth}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="ballGirth"
                      label="Last Ball Girth"
                      type="number"
                      defaultValue={lastBallGirth}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="heelGirth"
                      label="Last Heel Girth"
                      type="number"
                      defaultValue={lastHeelGirth}
                      sx={{ width: '100%' }}

                    />

                  </Stack>

                  <Stack direction="row" spacing={1} >

                    <TextField
                      id="heel"
                      label="Last Heel Height"
                      type="number"
                      defaultValue={lastHeightHeel}
                      onChange={(event) => { getHightHeel(event) }}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="toeHeight"
                      label="Last Toe Height"
                      type="number"
                      defaultValue={lastToeHeigth}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="lastLenght"
                      label="Last Length"
                      type="number"
                      defaultValue={lastLength}
                      sx={{ width: '100%' }}

                    />
                    <TextField
                      id="lastWidth"
                      label="Last Width"
                      type="number"
                      defaultValue={lastWidth}
                      sx={{ width: '100%' }}

                    />

                  </Stack>

                  <Stack direction="row" spacing={1} >

                    <TextField
                      id="description"
                      label="Description"
                      multiline
                      rows={4}
                      defaultValue={lastDescription}
                      sx={{ width: '100%' }}
                    />

                  </Stack>

                </>
                :
                <>Loading ...</>
            }
            <Typography sx={{ marginBottom: 2 }}>Variants:</Typography>

            {prodVariant(whichProduct, false)}


          </Stack>
        </>
      }
    </Box>

  );
};

const styleAddLast = {
  position: 'absolute',
  top: '5%',
  left: '25%',
  // transform: 'translate(-50%, -50%)',
  width: '50%',
  minWidth: 500,
  height: '88%',
  bgcolor: '#ffffff',
  shadows: 'none',

  overflow: 'auto',
};

export default DetailsMerchantLasts;