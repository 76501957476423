import { useState, useEffect } from 'react';
import { Avatar, Link, Typography, Grid, Paper, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import logo from '../../Assets/logo.png';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import Signin from './Signin';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://3dfitter.com/">
                3D Fitter
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Auth = () => {

    const [whitchForm, setWhitchForm] = useState(<Signin />)

    const paperStyle = { padding: 20, width: '40vw', margin: "19px auto", backgroundColor: grey[50], borderRadius: 5 }
    const logoStyle = { backgroundColor: '#D9D9D9', margin: '10px 0', width: 110, height: 110 }

    const getWhitchForm = (whitchForm) => {
        console.log('Auth -> getWhitchForm -> witchPage : ' + whitchForm);

        if (whitchForm == 'signin') {
            setWhitchForm(<Signin />)
        }
        if (whitchForm == 'signup') {
            setWhitchForm(<Signup />)
        }
        if (whitchForm == 'forgotpassword') {
            setWhitchForm(<ForgotPassword />)
        }

    }

    useEffect(() => {


        console.log('Auth -> useEffect -> ');
        getWhitchForm('signin')
    }, []);


    return (

        <Grid sx={{
            backgroundImage: 'url(https://picsum.photos/600/800/)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundPosition: 'center', height: '100vh', paddingTop: 2
        }}>
            <Grid align='center'>
                <Avatar style={logoStyle} alt="3D Fitter" src={logo} />
                <Stack sx={{ bgcolor: '#ffffff6b', width: '43vw', borderRadius: 2 }}><h2>3D Fitter</h2></Stack>
            </Grid>
            <Paper elavation={12} style={paperStyle}>

                {
                    whitchForm
                }
                <Typography>
                    <Link onClick={() => { getWhitchForm('signin') }} >
                        Signin Here.
                    </Link>
                    &nbsp;|&nbsp;
                    <Link onClick={() => { getWhitchForm('signup') }} >
                        Sign Up Here.
                    </Link>
                    &nbsp;|&nbsp;
                    <Link onClick={() => { getWhitchForm('forgotpassword') }} >
                        Forgot Password?
                    </Link>
                </Typography>

            </Paper>
            <Grid2 sx={{ bottom: 0, bgcolor: '#FFFFFF', height: '5vh', width: '100vw', position: 'fixed', padding: '1vh' }}>
                <Copyright />
            </Grid2>
        </Grid>

    )
}

export default Auth