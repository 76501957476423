import '../Styles/App.css'
import React, { useState, createRef, useRef, useEffect, Component } from 'react'

import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material'

import { ExpandMore } from '@mui/icons-material'

import { blue, grey } from '@mui/material/colors'

import FaqsManager from '../Components/FaqsManager'

import FunFactManager from '../Components/FunFactManager'

const Faqs = ({ getWitchPage }) => {
    // const navigation = useNavigate()

    return (
        <Box sx={{ flexGrow: 1, flexDirection: 'column', paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
            <Typography sx={{
                borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
            }} variant="h4">FAQs / Fun Fact</Typography>

            <Accordion
                sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1, marginTop: 2,
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: blue[700] }} />}
                    aria-controls="panel2-content"
                    id="Cat-Last-Manager"
                    variant="h6"
                    sx={{ backgroundColor: grey[100], borderRadius: 1 }}
                >Manage FAQs
                </AccordionSummary>
                <AccordionDetails>
                    <FaqsManager />
                </AccordionDetails>
            </Accordion>

            <Accordion
                sx={{
                    borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                    borderWidth: 1, padding: 1, borderRadius: 1, marginTop: 2,
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: blue[700] }} />}
                    aria-controls="panel2-content"
                    id="Standard-Manager"
                    variant="h6"
                    sx={{ backgroundColor: grey[100], borderRadius: 1 }}
                >Manage Fun Fact
                </AccordionSummary>
                <AccordionDetails>
                    <FunFactManager />
                </AccordionDetails>
            </Accordion>

        </Box>
    )
}

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
}

export default Faqs