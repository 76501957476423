import shoesLastGrey from '../Assets/shoeLastGrey.png';
import '../Styles/App.css';
import React, { useState, useEffect } from 'react';
import { APIMainRequestGET, APIUrl } from '../Manager/CommManager.js';
import {
    Box, Avatar, Typography, Modal, Paper, Chip, Stack
} from '@mui/material';
import {
    Check, Clear, WarningAmber, Info
} from '@mui/icons-material';
import { experimentalStyled as styled } from '@mui/material';
import { green, grey, orange, red } from "@mui/material/colors";
import { formatDate } from '../Manager/Utils.js';
import SnackBarManager from '../Components/SnackBarManager.js';
import UploadMerchantLast from '../Components/UploadMerchantLast.js';
import DetailsMerchantLasts from '../Components/DetailsMerchantLast.js';

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: 'none'
    // color: theme.palette.text.secondary,
}));

const icon = (
    <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
        <Avatar alt="Add a new last" src={shoesLastGrey} sx={{ width: 100, height: 100 }} />
    </Paper>
);

const Products = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const UseQuery = () => new URLSearchParams(window.location.search)
    const [getProductsList, setProductsList] = useState([])
    const [getIDProduct, setIDProduct] = useState([])
    const [getIDLast, setIDLast] = useState()

    const [getOurProductsList, setOurProductsList] = useState()
    const [toDisplayOurProductsList, setToDisplayOurProductsList] = useState(false)

    // To add a last 
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = (whichProd) => {
        console.log('Products -> handleOpenModal -> id : ');
        setIDProduct(whichProd)
        setOpenModal(true)
    };
    const handleCloseModal = () => {
        console.log('Products -> handleCloseModal -> id : 0');
        setOpenModal(false);
        setIDProduct()
    }
    // To add a last 

    // To get detail and update a last 
    const [openModalShowLast, setOpenModalShowLast] = React.useState(false);
    const handleOpenModalShowLast = (whichProd, whichLast) => {
        console.log('Products -> handleOpenModalShowLast -> whichMerchantProd : ' + JSON.stringify(whichProd, null, 2));
        setOpenModalShowLast(true)
        setIDProduct(whichProd)
        setIDLast(whichLast)
    };
    const handleCloseModalShowLast = () => {
        console.log('Products -> handleCloseModalShowLast -> id : 0');
        setOpenModalShowLast(false);
        setIDProduct()
        setIDLast()
    }
    // To get detail and update a last 

    const [openMaxSizeModal, setOpenMaxSizeModal] = React.useState(false);
    const [openMessageModal, setOpenMessageModal] = React.useState('');
    const handleCloseMaxSizeModal = () => {
        setOpenMaxSizeModal(false)
    }

    // UPLOAD FILE

    const getHubShopProductList = () => {
        console.log('Products -> getHubShopProductList -> ');
        const query = UseQuery()
        const getToken = localStorage.getItem('localAccessToken') //getData('localAccessToken')
        const getMerchantShop = localStorage.getItem('localShop') //getData('localShop')
        console.log('Products -> useEffect -> getProductsList : ' + getProductsList);
        if (getToken !== null && getProductsList.length === 0) {

            console.log('Products -> useEffect -> Get getMerchantShop : ' + getMerchantShop);
            console.log('Products -> useEffect -> Get getToken : ' + getToken);
            const makeRequest = `${APIUrl}/shopify/products/?setToken=${getToken}&setShop=${getMerchantShop}`

            const settingsParams = {
                perPage: 0,
            }

            APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
                return response.getData
            }).then(function (myJson) {
                console.log('Products -> useEffect -> getProductsList : ' + JSON.stringify(myJson.products, null, 2))
                setProductsList(myJson.products)

            }).finally(() => {
                console.log('Products -> useEffect -> getProductsList -> Finally ->')
            })
        }

    }

    useEffect(() => {


        ourProductsList()

        getHubShopProductList()

    }, []);

    const ourProductsList = () => {
        setToDisplayOurProductsList(false)
        const fakeParams = {
            perPage: 0,
        }
        const merchantID = localStorage.getItem('merchantID')
        console.log('Products -> ourProductsList -> Our Products -> merchantID : ' + merchantID)
        const makeProdRequest = `${APIUrl}/merchants/product/list/${merchantID}`
        APIMainRequestGET(makeProdRequest, fakeParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Products -> ourProductsList -> getOurProductsList : ' + JSON.stringify(myJson, null, 2))
            setOurProductsList(myJson)

        }).finally(() => {
            console.log('Products -> ourProductsList -> getOurProductsList -> Finally ->')

            setToDisplayOurProductsList(true)

        })
    }

    // Function to return a merchant product by ID
    const getMerchantProductById = (id) => {
        return getProductsList.find(products => products.id === `${id}`);
    };

    // Function to return a product by ID
    const getProductById = (id) => {
        return getOurProductsList.find(product => product.id === `${id}`);
    };

    const shearchProdId = (id) => {

        const setReturn = getOurProductsList.find((prodId) => prodId.id === `${id}`) !== undefined
        return setReturn

    }

    const ImageProducts = (product) => {
        const { image } = product;
        const imgLink = image !== null ? image.src : 'https://picsum.photos/200'
        const imgAlt = image !== null ? image.alt : product.title
        return (
            <>
                <Avatar
                    sx={{ width: '7vw', height: '7vw' }}
                    src={imgLink}
                    alt={imgAlt}
                />
            </>
        )
    }

    const productStatus = (product) => {
        const { status, id } = product;
        const statusProd = status === 'active' ? <Chip label={status} variant="outlined" color="success" icon={<Check sx={{ color: green[800] }} />} />
            : <Chip label={status} variant="outlined" color="error" icon={<Clear sx={{ color: red[800] }} />} />

        const checkLast = shearchProdId(id) === true ? <Chip label="Last associated" variant="outlined" color="success" icon={<Check sx={{ color: green[800] }} />} />
            : <Chip label="No last associated" variant="outlined" color="warning" icon={<WarningAmber sx={{ color: orange[600] }} />} />
        return (
            <>
                <Stack direction="row" spacing={2} >
                    {checkLast}
                    {statusProd}
                </Stack>

            </>
        )
    }

    const prodVariant = (product, short) => {
        const { variants } = product
        const firstNItems = variants.slice(0, 5);
        return (
            short === true ?
                firstNItems.map((item) =>
                    <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
                )
                :
                variants.map((item) =>
                    <><Chip label={item.title} sx={{ margin: 1 }} variant="outlined" /></>
                )
        )
    }

    const htmlIntertpretor = (code) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: code }} />
        )
    }

    const closeModalRefreshList = () => {
        console.log('Products -> closeModalRefreshList - ')
        handleCloseModal()
        handleCloseModalShowLast()

        ourProductsList()
        getHubShopProductList()
    }

    const displayLastsList = (prodid) => {
        const prod = getProductById(prodid)
        return (
            <>
                {
                    toDisplayOurProductsList === true ?
                        prod.lasts.map((index) => (
                            <>
                                <Chip
                                    label={'S/N : ' + index.sn}
                                    variant="outlined"
                                    color="default"
                                    icon={<Info sx={{ color: grey[800] }} />}
                                    // onClick={() => { handleOpenModalShowLast(prod, index.sn) }}
                                />
                            </>
                        ))
                        : <></>
                }
            </>
        )

    }

    const productList = () => {

        return (
            <>
                <Box >

                    <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />

                    <Stack direction='row' spacing={2} useFlexGap sx={{ margin: 2, flexWrap: 'wrap' }}>
                        {
                            getProductsList.map((item) => //  sx={{ width: '100%', backgroundColor: grey[50], borderRadius: 5, BorderStyle: 'solid', BorderWidth: 1, borderColor: grey[200] }}
                                <>
                                    <Item>
                                        <Stack direction='column' spacing={2} sx={{ width: '98%' }}>
                                            <Stack direction='row' spacing={1}>

                                                {ImageProducts(item)}

                                                <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
                                                    <Typography>
                                                        <b>{item.title}</b>
                                                    </Typography>
                                                    <Typography>
                                                        <b>ID : </b>{item.id}
                                                    </Typography>
                                                </Stack>

                                            </Stack>

                                            <Stack direction='row' useFlexGap sx={{ flexWrap: 'wrap' }} spacing={1}>
                                                {/* {
                                                    shearchProdId(item.id) === false ?
                                                        <>
                                                            <Chip
                                                                label="Associate new last"
                                                                variant="outlined"
                                                                color="primary"
                                                                icon={<AddCircle sx={{ color: blue[800] }} />}
                                                                onClick={() => { handleOpenModal(item) }} />
                                                        </> : <>
                                                            <Chip
                                                                label="Associate new last"
                                                                variant="outlined"
                                                                color="primary"
                                                                icon={<AddCircle sx={{ color: blue[800] }} />}
                                                                onClick={() => { handleOpenModal(item) }} />
                                                            {
                                                                displayLastsList(item.id)
                                                            }
                                                        </>

                                                } */}
                                                

                                            </Stack>

                                            <Stack direction='row' spacing={1}>

                                                <Typography sx={{ color: 'text.secondary', margin: 2 }}>
                                                    <b>Created : </b>{formatDate(item.created_at, "date")}
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary', margin: 2 }}>
                                                    {productStatus(item)}
                                                </Typography>

                                            </Stack>
                                        </Stack>
                                    </Item>
                                </>
                            )
                        }
                    </Stack>

                </Box >

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute', top: '5vh', left: '25vw', width: '50vw',
                            height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4, overflow: 'overlay'
                        }}>
                        <UploadMerchantLast whichProduct={getIDProduct} closeModalRefreshList={closeModalRefreshList} />

                    </Box>
                </Modal>

                <Modal
                    open={openModalShowLast}
                    onClose={handleCloseModalShowLast}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute', top: '5vh', left: '10vw', width: '80vw', overflow: 'overlay',
                            height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                        }}>
                        <DetailsMerchantLasts whichProduct={getIDProduct} whichLast={getIDLast} closeModalRefreshList={closeModalRefreshList} />

                    </Box>
                </Modal>



            </>
        )

    }

    return (
        <Box>
            {productList()}
        </Box>
    );
};

const styleMaxSizeModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};



export default Products;