import logo from '../../Assets/logo.png';
import '../../Styles/App.css';
const Users = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    USERS
                </p>
                <div className="container">
                    <div className="title">
                        <h1>List of users for this merchant</h1>
                    </div>
                    {/* <button className="btn" onClick={() => navigate('/')}>
                        Got to home
                    </button> */}
                </div>

            </header>
        </div>
    );
};

export default Users;