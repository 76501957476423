import '../Styles/App.css';
import { useState } from 'react';
import {
    Box, Tab, Tabs, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { blue } from '@mui/material/colors';
import LastsCatManager from '../Components/LastsCatManager';
import StandardManager from '../Components/StandardManager';
import LastsFilesManager from '../Components/LastsFilesManager';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Settings = ({ getWitchPage }) => {
    // const navigation = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, flexDirection: 'column', paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
            <Typography sx={{
                borderColor: blue[700], borderStyle: 'solid', color: blue[700],
                borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
            }} variant="h4">Settings</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Manage Last category" {...a11yProps(0)} />
                    <Tab label="Manage size standard" {...a11yProps(1)} />
                    <Tab label="Manage Stored Lasts files" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <LastsCatManager />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <StandardManager />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
                <LastsFilesManager />
            </CustomTabPanel>
        </Box>
    );
};

export default Settings;