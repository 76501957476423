import { Height } from "@mui/icons-material"
import { grey } from "@mui/material/colors"

const Styles = {

  AppItemsSelected: {
    backgroundColor: '#3d85c626',
    fontSize: 30,
    fontWeight: 400,
    color: '#3d85c6',
    borderRadius: '13px',
  },
  AppItems: {
    backgroundColor: '#ffffff',
    fontSize: 30,
    fontWeight: 100,
    color: '#1c1c1c',
    borderRadius: '13px',
    
  },
  SectionStyleMinus: {
    height: 30,
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
    width: '50%',
    alignSelf: 'center'
  },
  inputStyle: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#7e2584',
    placeholderTextColor: '#cccccc',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#e9e9e9'
  },
  buttonDisableStyle: {
    backgroundColor: '#cccccc',
    borderWidth: 0,
    color: '#666666',
    borderColor: '#7DE24E',
    height: 50,
    alignItems: 'center',
    borderRadius: 5,
    marginLeft: 35,
    marginRight: 35,
    marginTop: '5%',
    marginBottom: '5%',
    width: "50%",
    alignSelf: 'center',
    fontSize: 25
  },
  inputStyleSuccess: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'green',
    placeholderTextColor: '#d0adff',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#00800033'
  },
  inputStyleError: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: 'red',
    placeholderTextColor: '#d0adff',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#ff00001f'
  },
  inputStyleDisable: {
    flex: 1,
    backgroundColor: '#f1f1f11f',
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'cccccc',
    placeholderTextColor: '#ffffff',
    width: "50%",
    height: 50,
    alignSelf: 'center'
  },

}

export default { Styles }