import '../Styles/App.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { APIUrl } from '../Manager/CommManager.js';
import {
    ListItemText, ListItem, Divider, Box, ListItemAvatar, Button, Avatar, Typography, IconButton, 
    Stack, Backdrop, CircularProgress
} from '@mui/material';
import {
    ViewInAr, Delete
} from '@mui/icons-material';
import { blue, grey } from "@mui/material/colors";
import SnackBarManager from '../Components/SnackBarManager.js';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', backgroundColor: grey[50], padding: 10, borderRadius: '10px' }}>
            <CircularProgress variant="determinate" {...props} size={120} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const UploadFileLast = ({ whichProduct, closeModalRefreshList }) => {
    // const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const [file, setFile] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    // UPLOAD FILE
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFile(selectedFiles);


        const names = Array.from(selectedFiles).map((file) => file.name);
        setFileNames(names);
        console.log('UploadFileLast -> handleDropzone -> ' + JSON.stringify(names, null, 2))
    };

    const removeFile = (index) => {
        const newFiles = file.filter((_, i) => i !== index);
        const newFileNames = fileNames.filter((_, i) => i !== index);

        setFile(newFiles);
        setFileNames(newFileNames);
    };

    const handleSubmit = (e) => {

        setLoading(true);

        e.preventDefault();

        if (!file) {
            alert("UploadFileLast -> handleSubmit -> Please select a file first!");
            return;
        }

        const formData = new FormData();
        file.forEach((file) => formData.append("files", file));
        const merchantID = localStorage.getItem('merchantID')
        formData.append('containerName', merchantID);

        try {
            axios.post(`${APIUrl}/PFLasts/multiupload`, formData, {
                // headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total || 1
                    const percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log("UploadFileLast -> handleSubmit -> percentageCompleted :", percentageCompleted);
                    setUploadProgress(percentageCompleted)
                }
            }).then((resp) => {
                console.log("UploadFileLast -> handleSubmit -> File uploaded successfully :", resp.data);
                if (resp.status === 200) {

                    setUploadProgress(0)
                    setLoading(false);
                    setOpen(true)
                    setMessageTypeDisplayed('success')
                    setMessageDisplayed('Your last as been uploaded !')

                } else {
                    setLoading(false);
                    setOpen(true)
                    setMessageTypeDisplayed('error')
                    setMessageDisplayed('Error during your lasst uploaded process')

                }

                setTimeout(() => {
                    setOpen(false)
                    setMessageTypeDisplayed('')
                    setMessageDisplayed('')
                    closeModalRefreshList()
                }, 3000);
            })

        } catch (error) {
            console.error("UploadFileLast -> handleSubmit -> There was an error uploading the file:", error);
            setOpen(false)
            setMessageTypeDisplayed('')
            setMessageDisplayed('')
            closeModalRefreshList()
            setUploadProgress(0);
        }
    };

    const DisplayFileList = () => {
        console.log("UploadFileLast -> displayFileList -> count fileNames : " + fileNames.length);
        console.log("UploadFileLast -> displayFileList -> fileNames : " + JSON.stringify(fileNames, null, 2));
        return (
            fileNames.map((name, index) =>
                <>
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => { removeFile(index) }}>
                                <Delete />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ViewInAr />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                            secondary='Secondary text'
                        />
                    </ListItem>
                </>
            )
        )
    }

    useEffect(() => {

        console.log('UploadMerchantLast -> useEffect -> whichProduct : ' + JSON.stringify(whichProduct, null, 2));


    }, []);

    const uploadForm = () => {

        return (
            <>
                <Box sx={{ overflow: 'scroll' }} >

                    <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        {/* <CircularProgress color="inherit" /> */}
                        <CircularProgressWithLabel value={uploadProgress} />
                    </Backdrop>

                    <Stack direction="column" spacing={1} >
                        <form onSubmit={handleSubmit}>

                            <>
                                <input id='multipleUploadField' type="file" multiple onChange={handleFileChange} accept='.ply, .stl, .obj' />
                                <br />
                            </>
                            <>
                                <Button
                                    sx={{
                                        mt: 1, mr: 1,
                                        borderColor: blue[800], borderStyle: 'solid',
                                        borderWidth: 1, backgroundColor: grey[50], color: blue[600]
                                    }}
                                    type="submit"
                                    variant="contained"
                                    size="large">
                                    Send
                                </Button>
                            </>

                        </form>
                        <Divider />
                        {
                            <DisplayFileList sx={{ width: '50hw' }} />
                        }
                    </Stack>
                </Box>

            </>
        )

    }

    return (
        <Box>
            {uploadForm()}
        </Box>
    );
};



export default UploadFileLast;