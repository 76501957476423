import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Button, Box, Avatar, Stack, Typography, CircularProgress,
    Backdrop, Modal, TextField, ToggleButtonGroup, ToggleButton,Autocomplete
} from '@mui/material';
import {
    Cancel, Save, ViewInAr, AddCircleOutline
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { blue, grey } from '@mui/material/colors';

import { APIMainRequestGET, APIMainRequestPATCH } from '../Manager/CommManager';
import UploadFileLast from './UploadManyFiles';
import leftFoot from '../Assets/leftFoot.png';
import rightFoot from '../Assets/rightFoot.png';


const UpdateLasts = ({ productID,  data, closeModalRefreshList }) => {

    const [loading, setLoading] = React.useState(false)
    const [getLastFileList, setLastFileList] = useState([])
    const [getFileName, setFileName] = React.useState(null)
    const [getSide, setSide] = React.useState(null)
    const [getStandard, setStandard] = useState([])
    const [isEditMode, setIsEditMode] = useState(false)


    const [productSelected, setProductSelected] = useState('')
    const [productNameSelected, setProductNameSelected] = useState('')
    const [lastIDSelected, setLastIDSelected] = useState('')
    const [lastSNSelected, setLastSNSelected] = useState('')

    //////////////// MODAL //////////////////////
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //////////////// MODAL //////////////////////

    const [getStandardSelected, setStandardSelected] = useState({})
    const [stdName, setStdName] = useState(null)
    const [stdSize, setStdSize] = useState(null)
    const [stdWidth, setStdWidth] = useState(null)

    const handleChangeStandard = (event, newStandard) => {
        console.log('UpdateLasts -> handleChangeStandard -> newSide : ' + JSON.stringify(newStandard, null, 2));
        setStdName(newStandard.name)
        setStdSize(newStandard.size)
        setStdWidth(newStandard.width)
    };

    const handleChangeLeftRight = (event, newSide) => {
        console.log('UpdateLasts -> handleChangeLeftRight -> newSide : ' + newSide);
        setSide(newSide);
    };

    const handleReset = () => {
        console.log('UpdateLasts -> handleReset -> ')
        // requestToSTLFile(sn)
        closeModalRefreshList()
    };

    const [isErrorsLenght, setErrorsLenght] = useState(false)
    const [isErrorsWidth, setErrorsWidth] = useState(false)
    const [isErrorsBallGirth, setErrorsBallgirth] = useState(false)
    const [isErrorsHeelGirth, setErrorsHeelGirth] = useState(false)
    const [isErrorsHeel, setErrorsHeel] = useState(false)
    const [isErrorsToeHeight, setErrorsToeHeight] = useState(false)
    const [isErrorsLastLenght, setErrorsLastLenght] = useState(false)
    const [isErrorsLastWidth, setErrorsLastWidth] = useState(false)

    const ErrorTextField = styled(TextField)(({ theme, error }) => ({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
            },
            "&:hover fieldset": {
                borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
            },
            "&.Mui-focused fieldset": {
                borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
            },
        },
    }));
    const validateDecimal = (value) => /^\d*\.?\d*$/.test(value);
    const handleKeyLenght = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLenght(false) } else { setErrorsLenght(true) }
    };
    const handleKeyWidth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsWidth(false) } else { setErrorsWidth(true) }
    };
    const handleKeyBallgirth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsBallgirth(false) } else { setErrorsBallgirth(true) }
    };
    const handleKeyHeelGirth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsHeelGirth(false) } else { setErrorsHeelGirth(true) }
    };
    const handleKeyHeel = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsHeel(false) } else { setErrorsHeel(true) }
    };
    const handleKeyToeHeight = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsToeHeight(false) } else { setErrorsToeHeight(true) }
    };
    const handleKeyLastLenght = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLastLenght(false) } else { setErrorsLastLenght(true) }
    };
    const handleKeyLastWidth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLastWidth(false) } else { setErrorsLastWidth(true) }
    };

    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm();

    const onSubmit = (formData) => {
        console.log('Last -> onSubmit -> productID : ' + productSelected);
        console.log('Last -> onSubmit -> lastIDSelected : ' + lastIDSelected);
        console.log('Last -> onSubmit -> lastSNSelected : ' + lastSNSelected);
        console.log('Last -> onSubmit -> productNameSelected : ' + productNameSelected);
        console.log('Last -> onSubmit -> lastWidth : ' + formData.lastWidth + ' - data.lastWidth : ' + data.lastLenght);

        const dataFileName = data.linkPly.split('/').pop()
        const useStateDataFileName = getFileName
        console.log('Last -> onSubmit -> getFileName : ' + useStateDataFileName + ' - dataFileName : ' + dataFileName);

        const setupParams = {
            id: lastIDSelected,
            sn: lastSNSelected,
            name: productNameSelected,
            linkPly: getFileName != null ? useStateDataFileName : dataFileName,
            standard: stdName != null ? stdName : data.standard,
            size: stdSize != null ? stdSize : data.size,
            sizeWidth: stdWidth != null ? stdWidth : data.sizeWidth,
            side: getSide != null ? getSide : data.side,
            brand: formData.brand != undefined ? formData.brand : data.brand,
            model: formData.model != undefined ? formData.model : data.model,
            gender: formData.gender != undefined ? formData.gender : data.gender,
            type: formData.type != undefined ? formData.type : data.type,
            lenght: formData.lenght != undefined ? formData.lenght : data.lenght,
            width: formData.width != undefined ? formData.width : data.width,
            ballBirth: formData.ballGirth != undefined ? formData.ballGirth : data.ballBirth,
            heelBirth: formData.heelGirth != undefined ? formData.heelGirth : data.heelBirth,
            heel: formData.heel != undefined ? formData.heel : data.heel,
            toeHeight: formData.toeHeight != undefined ? formData.toeHeight : data.toeHeight,
            lastLenght: formData.lastLenght != undefined ? formData.lastLenght : data.lastLenght,
            lastWidth: formData.lastWidth != undefined ? formData.lastWidth : data.lastWidth,
            description: formData.description != undefined ? formData.description : data.description,
            active: formData.active != undefined ? formData.active : data.active,
        }
        console.log('Last -> onSubmit -> setupParams : ' + setupParams);
        lastUpdate(productSelected, lastIDSelected, setupParams)

    };

    const lastFileList = () => {
        console.log('UpdateLasts -> lastFileList -> ')

        const merchantID = localStorage.getItem('merchantID')
        const settingsParams = {
            value: 0
        }
        console.log('UpdateLasts -> lastFileList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/last/filelist/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            // console.log('Last -> lastFileList : ' + JSON.stringify(myJson, null, 2))

            const addButton = <>
                <Button variant="text" startIcon={<AddCircleOutline />}
                    onClick={handleOpen}>
                    Last files
                </Button>
            </>

            const tmpList = []
            tmpList.push(addButton)
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];

                tmpList.push(element)
            }

            setLastFileList(tmpList)

        }).finally(() => {
            console.log('UpdateLasts -> lastFileList -> Finally : ')

        })

    }

    const getAllStandard = () => {
        const settingsParams = {
            perPage: 0,
        }
        const merchantID = localStorage.getItem('merchantID')
        const makeRequest = `/lcs/std/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('UpdateLasts -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('UpdateLasts -> getStandardList -> Finally : ')
        })
    }

    const FileList = ({ data }) => {
        // console.log('UpdateLasts -> FileList -> item : ' + JSON.stringify(item, null, 2));
        const fileNameWithExtension = data.linkPly.split('/').pop();
        // console.log('UpdateLasts -> FileList -> fileNameWithExtension : ' + fileNameWithExtension);
        return (
            <Autocomplete
                value={getFileName != null ? getFileName : fileNameWithExtension}
                onChange={(event, newValue) => {
                    setFileName(newValue);
                }}
                id="controllable-states-demo"
                options={getLastFileList}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Controllable" />}
            />
        )
    }

    const DisplayStandardList = ({ item, index }) => {
        // console.log('Last -> FileList -> item : ' + JSON.stringify(item, null, 2));
        const options = getStandard.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });
        return (
            <>
                <Stack direction="column" spacing={2}>

                    <Autocomplete
                        // value={stdName != null ? `${stdName} - ${stdSize} - ${stdWidth}` : `${item.standard} - ${item.size} - ${item.sizeWidth}`}
                        onChange={(event, newValue) => {
                            handleChangeStandard(event, newValue);
                        }}
                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => `Name : ${option.name} - Size : ${option.size} - Width : ${option.width}`}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Select standard size" />}

                    />
                    <Stack direction="row" spacing={2}>
                        <Typography variant="body1" gutterBottom><b>Name : </b>{stdName != null ? stdName : data.standard}</Typography>
                        <Typography variant="body1" gutterBottom><b>Size : </b>{stdSize != null ? stdSize : data.size}</Typography>
                        <Typography variant="body1" gutterBottom><b>Width : </b>{stdWidth != null ? stdWidth : data.sizeWidth}</Typography>
                    </Stack>

                </Stack>
            </>
        )
    }

    const DisplayLastInfo = ({ data }) => {

        return (
            <>
                <Stack direction="row" spacing={1} >

                    <Controller name="brand" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <TextField id="brand" label="Brand" defaultValue={data.brand}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange}
                        />

                    </>)} />

                    <Controller name="model" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <TextField id="model" label="Model" defaultValue={data.model}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange}
                        />

                    </>)} />
                </Stack>

                <Stack direction="row" spacing={1} >
                    <Controller name="gender" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <TextField id="gender" label="Gender" defaultValue={data.gender}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange}
                        />

                    </>)} />
                    <Controller name="type" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <TextField id="type" label="Type" defaultValue={data.type}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange}
                        />

                    </>)} />

                </Stack>

                <Stack direction="row" spacing={1} >

                    <Controller name="lenght" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='lenght' id="lenght" label="Foot Length" defaultValue={data.lenght}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyLenght} error={isErrorsLenght} helperText={isErrorsLenght ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                    <Controller name="width" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='width' id="width" label="Foot Width" defaultValue={data.width}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyWidth} error={isErrorsWidth} helperText={isErrorsWidth ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                </Stack>

                <Stack direction="row" spacing={1} >
                    <Controller name="ballGirth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='ballGirth' id="ballGirth" label="Last Ball Girth" defaultValue={data.ballBirth}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyBallgirth} error={isErrorsBallGirth} helperText={isErrorsBallGirth ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                    <Controller name="heelGirth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='heelGirth' id="heelGirth" label="Last Heel Girth" defaultValue={data.heelBirth}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyHeelGirth} error={isErrorsHeelGirth} helperText={isErrorsHeelGirth ? "Please enter a valid number" : ""}
                        />

                    </>)} />

                </Stack>

                <Stack direction="row" spacing={1} >

                    <Controller name="heel" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='heel' id="heel" label="Last Heel Height" defaultValue={data.heel}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyHeel} error={isErrorsHeel} helperText={isErrorsHeel ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                    <Controller name="toeHeight" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='toeHeight' id="toeHeight" label="Last Toe Height" defaultValue={data.toeHeight}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyToeHeight} error={isErrorsToeHeight} helperText={isErrorsToeHeight ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                </Stack>

                <Stack direction="row" spacing={1} >
                    <Controller name="lastLenght" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='lastLenght' id="lastLenght" label="Last Length" defaultValue={data.lastLenght}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyLastLenght} error={isErrorsLastLenght} helperText={isErrorsLastLenght ? "Please enter a valid number" : ""}
                        />

                    </>)} />
                    <Controller name="lastWidth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <ErrorTextField name='lastWidth' id="lastWidth" label="Last Width" defaultValue={data.lastWidth}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }}
                            onKeyUp={handleKeyLastWidth} error={isErrorsLastWidth} helperText={isErrorsLastWidth ? "Please enter a valid number" : ""}
                        />

                    </>)} />

                </Stack>

                <Stack direction="row" spacing={1} >

                    <Controller name="description" control={control} render={({ field: { onChange, onBlur, value } }) => (<>

                        <TextField id="description" label="Description" multiline rows={4} defaultValue={data.description}
                            sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                    </>)} />

                </Stack>

            </>
        )
    }

    const returnFormUI = (itemLast) => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography><b>S/N :</b> {data.sn}</Typography>
                            <Typography><b>ID :</b> {data.id}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <ToggleButtonGroup
                                sx={{ color: grey[800] }}
                                value={getSide != null ? getSide : data.side}
                                onChange={handleChangeLeftRight}
                                exclusive aria-label="Platform">
                                <ToggleButton value="left">
                                    <Avatar alt="Remy Sharp" src={leftFoot} />
                                    <Typography> Left </Typography>
                                </ToggleButton>
                                <ToggleButton value="right">
                                    <Typography> Right </Typography>
                                    <Avatar alt="Remy Sharp" src={rightFoot} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Stack direction="column" spacing={2}>
                                <FileList data={data} />
                                <Stack direction="row" spacing={1} margin={1} >
                                    <Avatar sx={{ bgcolor: grey[500], width: '2vw', height: '2vw' }}>
                                        <ViewInAr />
                                    </Avatar>
                                    <Typography>{getFileName != null ? getFileName : data.linkPly.split('/').pop()}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <DisplayStandardList data={data} />
                        <DisplayLastInfo data={data} />
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" startIcon={<Cancel />} sx={{ color: grey[500] }} onClick={() => { closeModalRefreshList() }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="outlined" startIcon={<Save />} sx={{ color: blue[500] }}>
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </>
        )
    }

    useEffect(() => {
        console.log('UpdateLasts -> useEffect');
        console.log('UpdateLasts -> useEffect -> Data : ' + JSON.stringify(data, null, 2));
        console.log('UpdateLasts -> useEffect -> productID : ' + productID.id);
        setProductSelected(productID.id)
        setProductNameSelected(productID.title)
        setLastIDSelected(data.id)
        setLastSNSelected(data.sn)
        lastFileList()
        getAllStandard()

    }, []);

    const lastUpdate = (prodID, lastID, bodyParams) => {
        setLoading(true);
        console.log('UpdateLasts -> lastUpdate -> ')

        const merchantID = localStorage.getItem('merchantID')
        const productID = prodID
        const lastsID = lastID

        console.log('UpdateLasts -> lastUpdate -> settingsParams : ' + JSON.stringify(bodyParams, null, 2))
        const makeRequest = `/lasts/update/${merchantID}/${productID}/${lastsID}`
        console.log('UpdateLasts -> lastUpdate -> makeRequest : ' + makeRequest)
        APIMainRequestPATCH(makeRequest, bodyParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            // console.log('UpdateLasts -> Last : ' + JSON.stringify(myJson, null, 2))
            setLoading(false);
            closeModalRefreshList()

        }).finally(() => {
            console.log('Last -> standardUpdate -> Finally : ')

        })

    }

    return (
        <Box sx={{ width: '100%', '& .actions': { color: 'text.secondary', }, '& .textPrimary': { color: 'text.primary', } }} >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            ><CircularProgress size={50} sx={{ color: grey[50] }} /></Backdrop>

            { returnFormUI() }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '5vh', left: '10vw', width: '80vw', overflow: 'overlay',
                    height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Upload your lasts
                    </Typography>

                    <UploadFileLast closeModalRefreshList={closeModalRefreshList} />
                </Box>
            </Modal>
        </Box>
    );
};
const styleAddLast = {
    position: 'absolute',
    top: '5%',
    left: '15%',
    // transform: 'translate(-50%, -50%)',
    width: '70%',
    minWidth: 500,
    height: '88%',
    bgcolor: 'background.paper',
    padding: 1,
    overflow: 'auto',
};

const styleModal = {
    position: 'absolute',
    top: '30%',
    left: '35%',
    borderRadius: 4,
    width: '30%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};

export default UpdateLasts;