
import '../Styles/App.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { APIMainRequestDELETE, APIMainRequestGET, APIUrl } from '../Manager/CommManager.js';
import {
  ListItemText, ListItem, Divider, Box, ListItemAvatar,
  Button, Avatar, Typography, useMediaQuery, IconButton, Chip, Stack,
  Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog
} from '@mui/material';
import {
  ViewInAr, Delete,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';

import { blue, grey, red } from "@mui/material/colors";
import SnackBarManager from '../Components/SnackBarManager.js';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', backgroundColor: grey[50], padding: 10, borderRadius: '10px' }}>
      <CircularProgress variant="determinate" {...props} size={120} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const LastsFilesManager = ({ whichProduct, closeModalRefreshList }) => {
  // const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [messageDisplayed, setMessageDisplayed] = useState('')
  const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

  const [file, setFile] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileNamesStored, setFileNamesStored] = useState([]);
  // UPLOAD FILE
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFile(selectedFiles);


    const names = Array.from(selectedFiles).map((file) => file.name);
    setFileNames(names);
    console.log('UploadFileLast -> handleDropzone -> ' + JSON.stringify(names, null, 2))
  };

  const removeFile = (index) => {
    const newFiles = file.filter((_, i) => i !== index);
    const newFileNames = fileNames.filter((_, i) => i !== index);

    setFile(newFiles);
    setFileNames(newFileNames);
  };

  const removeAllTmpFiles = () => {
    setFile([]);
    setFileNames([]);
  }

  const handleSubmit = (e) => {

    setLoading(true);

    e.preventDefault();

    if (!file) {
      alert("LastsFilesManager -> handleSubmit -> Please select a file first!");
      return;
    }

    const formData = new FormData();
    file.forEach((file) => formData.append("files", file));
    const merchantID = localStorage.getItem('merchantID')
    formData.append('containerName', merchantID);

    try {
      axios.post(`${APIUrl}/PFLasts/multiupload`, formData, {
        // headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total || 1
          const percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log("LastsFilesManager -> handleSubmit -> percentageCompleted :", percentageCompleted);
          setUploadProgress(percentageCompleted)
        }
      }).then((resp) => {
        console.log("LastsFilesManager -> handleSubmit -> File uploaded successfully :", resp.data);
        if (resp.status === 200) {

          setUploadProgress(0)
          setLoading(false);
          setOpen(true)
          setMessageTypeDisplayed('success')
          setMessageDisplayed('Your last as been uploaded !')

          getLastsFiles()
          setFileNames([])

        } else {
          setLoading(false);
          setOpen(true)
          setMessageTypeDisplayed('error')
          setMessageDisplayed('Error during your lasst uploaded process')

          getLastsFiles()
          setFileNames([])

        }

        setTimeout(() => {
          setOpen(false)
          setMessageTypeDisplayed('')
          setMessageDisplayed('')
          closeModalRefreshList()
          
        }, 3000);
      })

    } catch (error) {
      console.error("LastsFilesManager -> handleSubmit -> There was an error uploading the file:", error);
      setOpen(false)
      setMessageTypeDisplayed('')
      setMessageDisplayed('')
      closeModalRefreshList()
      setUploadProgress(0);
      getLastsFiles()
    }
  };

  const DisplayFileList = () => {
    console.log("LastsFilesManager -> displayFileList -> count fileNames : " + fileNames.length);
    console.log("LastsFilesManager -> displayFileList -> fileNames : " + JSON.stringify(fileNames, null, 2));
    return (

      fileNames.map((name, index) =>
        <>
          <ListItem
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => { removeFile(index) }}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <ViewInAr />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary='Secondary text'
            />
          </ListItem>
        </>
      )
    )
  }

  const initRemoveStoredFile = (name) => {
    setIdFileLast(name)
    setTitleAlert('Confirm to delete !')
    setMessageAlert(`Are you sure to should delete ${name} file ?`)
    setAlertIsOpen(true)
  }

  const removeStoredFile = (index) => {
    console.log('LastsFilesManager -> removeStoredFile -> index : ' + index)

    const merchantID = localStorage.getItem('merchantID')
    const settingsParams = { value: 0 }

    console.log('LastsFilesManager -> removeStoredFile -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
    const makeRequest = `/PFLasts/file/delete/${merchantID}/${index}`
    APIMainRequestDELETE(makeRequest, settingsParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {
      console.log('LastsFilesManager -> removeStoredFile : ' + JSON.stringify(myJson, null, 2))

      setFileNamesStored(myJson)

    }).finally(() => {
      console.log('LastsFilesManager -> removeStoredFile -> Finally : ')

    })
  };

  const DisplayStoredFileList = () => {
    console.log("LastsFilesManager -> DisplayStoredFileList -> count fileNames : " + fileNamesStored.length);
    console.log("LastsFilesManager -> DisplayStoredFileList -> fileNames : " + JSON.stringify(fileNamesStored, null, 2));
    return (

      fileNamesStored.map((name, index) =>
        <>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => { initRemoveStoredFile(name) }}
                sx={{ color: red[500] }}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <ViewInAr />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary='Secondary text'
            />
          </ListItem>
        </>
      )

    )
  }

  const getLastsFiles = () => {

    console.log('LastsFilesManager -> getLastsFiles -> ')

    const merchantID = localStorage.getItem('merchantID')
    const settingsParams = {
      value: 0
    }
    console.log('LastsFilesManager -> getLastsFiles -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
    const makeRequest = `/last/filelist/${merchantID}`
    APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {
      console.log('LastsFilesManager -> getLastsFiles : ' + JSON.stringify(myJson, null, 2))

      setFileNamesStored(myJson)
      removeAllTmpFiles()

    }).finally(() => {
      console.log('LastsFilesManager -> getLastsFiles -> Finally : ')

    })

  }

  // Alert View
  const [idFileLast, setIdFileLast] = useState('')
  const [idProduct, setIdProduct] = useState('')
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [titleAlert, setTitleAlert] = useState('')
  const [messageAlert, setMessageAlert] = useState('')
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleAlertCloseAgree = () => {
    console.log('DialogAlert -> handleAlertCloseAgree');

    removeStoredFile(idFileLast)
    setAlertIsOpen(false);
    getLastsFiles()
  };

  const handleAlertCloseDisagree = () => {
    console.log('DialogAlert -> handleAlertCloseDisagree');
    setAlertIsOpen(false);
  };

  const DialogAlert = ({ title, message, cancelBtn, validateBtn }) => {
    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={alertIsOpen}
          onClose={handleAlertCloseDisagree}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title"> {title} </DialogTitle>
          <DialogContent>
            <DialogContentText> {message} </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleAlertCloseDisagree}> {cancelBtn} </Button>
            <Button onClick={handleAlertCloseAgree} autoFocus> {validateBtn} </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  // Alert View

  useEffect(() => {

    console.log('UploadMerchantLast -> useEffect -> whichProduct : ' + JSON.stringify(whichProduct, null, 2));
    getLastsFiles()

  }, []);

  const uploadForm = () => {

    return (
      <>
        <Box sx={{ overflow: 'scroll' }} >

          <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
          <DialogAlert title={titleAlert} message={messageAlert} cancelBtn={'Cancel'} validateBtn={'Confirm'} />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            {/* <CircularProgress color="inherit" /> */}
            <CircularProgressWithLabel value={uploadProgress} />
          </Backdrop>

          <Stack direction="column" spacing={1} >

            <Stack direction="row" spacing={1}>

              <Stack direction="column" spacing={1} ><Divider>
                <Chip label="Stored files" size="small" />
              </Divider>
                <DisplayStoredFileList />
              </Stack>

              <Divider orientation="vertical" flexItem />

              <Stack direction="column" spacing={1} >

                <form onSubmit={handleSubmit}>
                  <>
                    <input id='multipleUploadField' type="file" multiple onChange={handleFileChange} accept='.ply, .stl, .obj' />
                    <br />
                  </>
                  <>
                    <Button
                      sx={{
                        mt: 1, mr: 1,
                        borderColor: blue[800], borderStyle: 'solid',
                        borderWidth: 1, backgroundColor: grey[50], color: blue[600]
                      }}
                      type="submit"
                      variant="contained"
                      size="large">
                      Upload there files
                    </Button>
                  </>

                </form>
                <Divider>
                  <Chip label="Files ready to upload" size="small" />
                </Divider>
                <DisplayFileList />
              </Stack>

            </Stack>


          </Stack>
        </Box>

      </>
    )

  }

  return (
    <Box>
      {uploadForm()}
    </Box>
  );
};



export default LastsFilesManager;